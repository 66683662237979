import { Tiles } from './Tiles';
import React, { useCallback, useEffect, useState } from 'react';
import {
  calculateMove,
  defaultGameState,
  getHighScore,
  getInitialGameState,
  getNewGameState,
  getPlayState,
  populateNewTile,
} from './tile.service';
import { Direction } from '../../shared/models/direction';
import { IGameState, PlayState } from './models/state';
import { InfoPanel } from './InfoPanel';
import { loadFromLocalStorage, LocalStorageKeysList, saveToLocalStorage } from '../../shared/services/utils';
import styled from 'styled-components/macro';
import { InputDirectionHandler } from '../../shared/components/input/InputDirectionHandler';
import { GameOverlay } from './GameOverlay';
import { useTwentyFortyEightApi } from '../../shared/api/2048';
import { CreateTwentyFortyEightStatArgs } from '../../shared/entities/2048';

export const TwentyFortyEight = (): JSX.Element => {
  const [gameState, setGameState] = useState<IGameState>(defaultGameState);
  const [highScore, setHighScore] = useState<number>(0);
  const [scoreDiff, setScoreDiff] = useState<number>(0);
  const [hasShownGameWon, sethHasShownGameWon] = useState<boolean>(false);
  const api = useTwentyFortyEightApi();

  useEffect(() => {
    setGameState(getInitialGameState());
    setHighScore(getHighScore());
  }, []);

  const saveGameStats = useCallback(async () => {
    try {
      const args: CreateTwentyFortyEightStatArgs = {
        gridSize: gameState.gridSize,
        name: '',
        score: gameState.score,
        tilesMoved: gameState.tilesMoved,
        tilesMerged: gameState.tilesMerged,
        biggestMove: gameState.biggestMove,
        biggestTile: gameState.biggestTile,
      };

      const savedScore = await api.createStat(args);

      const localGames = loadFromLocalStorage<string[]>(LocalStorageKeysList.TwentyFortyEightPastGames) || [];
      localGames.push(savedScore.id);
      saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightPastGames, localGames);
    } catch (e) {
      console.error(e);
    }
  }, [api, gameState]);

  const updateState = useCallback(
    (direction: Direction) => {
      const scoreStart = gameState.score;
      const movesBefore = gameState.tilesMoved;
      let newState = calculateMove(gameState, direction);
      setGameState(newState);
      setScoreDiff(newState.score - scoreStart);
      saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightGameState, newState);

      if (newState.score > highScore) {
        setHighScore(newState.score);
        saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightHighScore, highScore);
      }
      const shouldAddTile = newState.tilesMoved > movesBefore;

      setTimeout(async () => {
        const tiles = [...newState.tiles];
        const didAddTile = shouldAddTile ? populateNewTile(tiles) : false;
        if (didAddTile) {
          const playState = getPlayState(tiles);
          setGameState({
            ...newState,
            tiles: tiles,
            playState,
          });
          if (playState === PlayState.GameOver) {
            await saveGameStats();
            newState = getNewGameState(gameState.gridSize);
          }
          saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightGameState, newState);
        }
      }, 100);
    },
    [gameState, setGameState, setScoreDiff, highScore, saveGameStats],
  );

  const handleDirectionInput = useCallback(
    (direction: Direction) => {
      updateState(direction);
    },
    [updateState],
  );

  const resetGame = useCallback(() => {
    const newState = getNewGameState();
    saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightGameState, newState);
    sethHasShownGameWon(false);
    setGameState(newState);
  }, [setGameState]);

  const continuePlaying = useCallback(() => {
    sethHasShownGameWon(true);
  }, [sethHasShownGameWon]);

  const canPlay =
    gameState.playState === PlayState.CanPlay || (gameState.playState === PlayState.GameWon && hasShownGameWon);

  return (
    <Container>
      <InfoPanel highScore={highScore} gameState={gameState} onNewGame={resetGame} scoreDiff={scoreDiff} />

      {!canPlay && (
        <GameOverlay
          gameState={gameState}
          onNewGameClick={resetGame}
          onContinueGameClick={continuePlaying}
          hasShownGameWon={hasShownGameWon}
        />
      )}

      <InputDirectionHandler onDirectionInput={handleDirectionInput} disabled={!canPlay}>
        <Game gridSize={gameState.gridSize}>
          <Grid>
            {Array(gameState.gridSize * gameState.gridSize)
              .fill(0)
              .map((val, index) => (
                <GridCell key={`grid-cell${index}`} />
              ))}
          </Grid>
          <Tiles tiles={gameState.tiles} />
        </Game>
      </InputDirectionHandler>
      <HowToPlayPanel>
        <strong>HOW TO PLAY: </strong>
        <HowToPlayKeyboard>
          Use your <strong>arrow keys </strong>
        </HowToPlayKeyboard>
        <HowToPlayTouch>
          Swipe with <strong>your fingers </strong>
        </HowToPlayTouch>
        to move the tiles. Tiles with the same number
        <strong> merge into one</strong> when they touch. Add them up to reach
        <strong> 2048!</strong>
      </HowToPlayPanel>
    </Container>
  );
};

const Container = styled.div`
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: min-content;
`;

interface IContainerProps {
  gridSize: number;
}

const Game = styled.div<IContainerProps>`
  --game-size: 80vmin;
  --grid-size: ${({ gridSize }: IContainerProps) => gridSize};
  --cell-size: calc(var(--game-size) / var(--grid-size));
  --tile-size: calc(var(--game-size) / var(--grid-size));
  background-color: #bbada0;
  border-radius: 6px;
  height: var(--game-size);
  min-height: var(--game-size);
  position: relative;
  width: var(--game-size);

  @media (max-device-width: 415px) {
    --game-size: 90vmin;
  }
`;

const Grid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: repeat(var(--grid-size), 1fr);
  grid-template-columns: repeat(var(--grid-size), 1fr);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const GridCell = styled.div`
  background: #eee4da59;
  border-radius: 3px;
  box-sizing: border-box;
  margin: 5px;
`;

const HowToPlayPanel = styled.div`
  margin: 2rem 0;
  padding: 1rem;
  border-radius: 6px;
  text-align: start;
  background-color: #eee4daba;
`;

const HowToPlayKeyboard = styled.div`
  display: none;
  @media (min-device-width: 415px) {
    display: inline;
  }
`;

const HowToPlayTouch = styled.div`
  display: none;
  @media (max-device-width: 415px) {
    display: inline;
  }
`;
