import styled from 'styled-components/macro';
import { Button } from './buttons/Button';
import { FadeIn } from '../theme/animations';

interface IPromptOptions<T> {
  isOpen: boolean;
  onConfirm: (newValue?: T) => void;
  onClose: () => void;
  value?: T;
  message?: string;
}

export const Prompt = <T extends any>({
  isOpen,
  onClose,
  onConfirm,
  value,
  message,
}: IPromptOptions<T>): JSX.Element => {
  const handleConfirm = () => {
    onConfirm(value);
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <PromptContainer isOpen={isOpen}>
      <PromptContent>
        <Message>{message ?? 'Are you sure?'}</Message>
        <Footer>
          <Button width="25%" onClick={handleClose}>
            No
          </Button>
          <Button width="25%" onClick={handleConfirm}>
            Yes
          </Button>
        </Footer>
      </PromptContent>
    </PromptContainer>
  );
};

interface IPromptContainerProps {
  isOpen: boolean;
}

const PromptContainer = styled.div<IPromptContainerProps>`
  align-content: center;
  animation: ${FadeIn} 250ms ease;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }: IPromptContainerProps) => (isOpen ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  left: 0;
  padding-top: 10%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PromptContent = styled.div`
  background: #eee4da;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: min-content;
  justify-self: center;
  padding: 1rem 0;
  width: 25%;
  z-index: 100;

  @media (max-device-width: 415px) {
    width: 75%;
  }
`;

const Footer = styled.div`
  align-content: center;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

const Message = styled.div`
  padding: 0 1rem 1rem 1rem;
`;
