import { WebEnv } from './env';
import axios, { AxiosInstance } from 'axios';

interface ISettings {
  isDeveloperModeEnabled: boolean;
}

export class ConfigService {
  private readonly config: WebEnv;
  private readonly apiUrl: string;
  public readonly api: AxiosInstance;
  public settings: ISettings;

  constructor() {
    this.config = { ...(process.env as any) };

    if (!this.config.REACT_APP_API_URL) {
      throw new Error('Environment configuration malformed');
    }

    this.apiUrl = this.config.REACT_APP_API_URL;
    this.api = this.createApi(this.config.REACT_APP_API_URL);
    this.settings = {
      isDeveloperModeEnabled: true,
    };
  }

  private createApi = (apiUrl: string): AxiosInstance => {
    const api = axios.create({
      baseURL: apiUrl,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    });

    api.interceptors.response.use(
      response => response,
      error => Promise.reject(error),
    );

    return api;
  };
}
