export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const LocalStorageKeysList = {
  TwentyFortyEightGameState: 'TwentyFortyEightGameState',
  TwentyFortyEightHighScore: 'TwentyFortyEightHighScore',
  TwentyFortyEightHighScores: 'TwentyFortyEightHighScores', // Depricated
  TwentyFortyEightPastGames: 'TwentyFortyEightPastGames',
};

export const saveToLocalStorage = (key: string, obj: any) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const loadFromLocalStorage = <T extends any>(key: string): T | undefined => {
  const obj = localStorage.getItem(key);
  return obj ? (JSON.parse(obj) as T) : undefined;
};

export const removeItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
