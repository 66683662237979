import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { IconButton } from '../buttons/IconButton';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { RouteDefinitions } from '../../../routes';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

export const Navbar = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const onBackButtonClick = useCallback(() => {
    history.push('/');
  }, [history]);

  const showBackButton = useMemo(() => location.pathname !== RouteDefinitions.Index, [location]);

  return (
    <NavContainer>{showBackButton && <IconButton size="2x" color="white" icon={faChevronCircleLeft} onClick={onBackButtonClick} />}</NavContainer>
  );
};

const NavContainer = styled.div`
  align-content: center;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  > * {
    margin: 0.5rem 0 0 0.5rem;
  }
`;
