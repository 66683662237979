import React from 'react';
import { TwentyFortyEightRoot } from './pages/2048';
import { Route, Router, Switch } from 'react-router-dom';
import { RouteDefinitions } from './routes';
import { createBrowserHistory } from 'history';
import { Home } from './pages/Home';
import { TicTacToe } from './pages/ticTacToe/pages/TicTacToe';
import { Snake } from './pages/snake/pages/Snake';
import styled from 'styled-components/macro';
import { Navbar } from './shared/components/layout/Navbar';
import { ApiTestRoot } from './pages/apiTest/ApiTest';
import { ConfigProvider } from './core/config';
import { StyledEngineProvider } from '@mui/styled-engine';

export const history = createBrowserHistory();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ConfigProvider>
        <BaseLayout>
          <Router history={history}>
            <Navbar />
            <Switch>
              <Route path={RouteDefinitions.TwentyFortyEight} component={TwentyFortyEightRoot} />
              <Route path={RouteDefinitions.TicTacToe} component={TicTacToe} />
              <Route path={RouteDefinitions.Snake} component={Snake} />
              <Route path={RouteDefinitions.ApiTest} component={ApiTestRoot} />
              <Route component={Home} />
            </Switch>
          </Router>
        </BaseLayout>
      </ConfigProvider>
    </StyledEngineProvider>
  );
}

export default App;

const BaseLayout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding: 1rem;
`;
