import { Tile } from '../../shared/models/tile';
import { Direction } from '../../shared/models/direction';
import {
  getRandomInt,
  loadFromLocalStorage,
  LocalStorageKeysList,
  removeItemFromLocalStorage,
  saveToLocalStorage,
} from '../../shared/services/utils';
import { HighScores, IGameState, ITilesUpdate, PlayState } from './models/state';

const buildStartingTiles = (gridSize: number): Tile[] => {
  const tiles: Tile[] = [];
  let count = 0;
  const maxCount = gridSize * gridSize;
  const r1 = getRandomInt(0, maxCount);
  let r2 = 0;
  do {
    r2 = getRandomInt(0, maxCount);
  } while (r2 === r1);
  const rand = [r1, r2];
  for (let i = 0; i < gridSize; i++) {
    for (let j = 0; j < gridSize; j++) {
      tiles.push({
        id: count,
        value: rand.includes(count) ? 2 : undefined,
        row: i,
        col: j,
      });
      count++;
    }
  }
  return tiles;
};

export const defaultGameState: IGameState = {
  tiles: [],
  score: 0,
  tilesMoved: 0,
  playState: PlayState.CanPlay,
  gridSize: 4,
  biggestMove: 0,
  tilesSpawned: 2,
  tilesMerged: 0,
  biggestTile: 2,
};

export const getNewGameState = (gridSize: number = 4): IGameState => {
  return {
    ...defaultGameState,
    tiles: buildStartingTiles(gridSize),
  };
};

export const getInitialGameState = (): IGameState => {
  const loadedState = loadFromLocalStorage<IGameState>(LocalStorageKeysList.TwentyFortyEightGameState);

  return verifyGameState(loadedState);
};

const verifyGameState = (gameState: IGameState | undefined): IGameState => {
  let state = gameState;
  let needsSaved = false;

  if (state?.gridSize !== 4) {
    state = getNewGameState();
    needsSaved = true;
  }

  if (!state.biggestMove) {
    state.biggestMove = 0;
    needsSaved = true;
  }

  if (!state.tilesMoved) {
    state.tilesMoved = (state as any).movesMade ?? 0;
    needsSaved = true;
  }

  if (!state.tilesMerged) {
    state.tilesMerged = 0;
    needsSaved = true;
  }

  if (!state.tilesSpawned) {
    state.tilesSpawned = 2;
    needsSaved = true;
  }

  if (!state.biggestTile) {
    state.biggestTile = state.tiles.reduce((agg, cur) => Math.max(cur.value ?? 0, agg), 0);
    needsSaved = true;
  }

  if (needsSaved) {
    saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightGameState, state);
  }
  return state;
};

export const getHighScore = (): number => {
  let highScore = loadFromLocalStorage<number>(LocalStorageKeysList.TwentyFortyEightHighScore);
  if (highScore !== undefined) {
    return highScore;
  }
  const legacyScores = loadFromLocalStorage<HighScores>(LocalStorageKeysList.TwentyFortyEightHighScores);
  console.log(legacyScores);
  if (legacyScores && legacyScores[4]) {
    highScore = legacyScores[4];
    saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightHighScore, highScore);
    removeItemFromLocalStorage(LocalStorageKeysList.TwentyFortyEightHighScores);
    return highScore;
  }

  saveToLocalStorage(LocalStorageKeysList.TwentyFortyEightHighScore, 0);

  return 0;
};

const swapTiles = (tiles: Tile[], start: number, end: number) => {
  const tempRow = tiles[end].row;
  tiles[end].row = tiles[start].row;
  tiles[start].row = tempRow;
  const tempCol = tiles[end].col;
  tiles[end].col = tiles[start].col;
  tiles[start].col = tempCol;
};

const sortTiles = (tiles: Tile[], direction?: Direction): Tile[] => {
  if (direction === undefined) {
    return tiles.sort((a, b) => (a.id > b.id ? 1 : -1));
  }
  return tiles.sort((a, b) => {
    const isHorizontal = direction === Direction.Left || direction === Direction.Right;
    const isReverse = direction === Direction.Right || direction === Direction.Down;
    const selector: keyof Tile = isHorizontal ? 'col' : 'row';
    const mult = isReverse ? -1 : 1;
    return (a[selector] > b[selector] ? 1 : -1) * mult;
  });
};

const moveRow = (tiles: Tile[], direction: Direction): ITilesUpdate => {
  let targIndex = 0;
  let tilesMoved = 0;
  let tilesMerged = 0;
  let score = 0;
  for (let curIndex = 1; curIndex < tiles.length; curIndex++) {
    sortTiles(tiles, direction);
    if (tiles[curIndex].value) {
      if (tiles[targIndex].value) {
        if (tiles[curIndex].value === tiles[targIndex].value) {
          // can merge to target
          const newValue = tiles[curIndex].value! * 2;
          tiles[curIndex].value = newValue;
          tiles[targIndex].value = undefined;
          score += newValue;
          swapTiles(tiles, curIndex, targIndex);
          tilesMoved++;
          tilesMerged++;
          targIndex++;
        } else if (curIndex !== targIndex + 1) {
          // can move to target + 1
          swapTiles(tiles, curIndex, targIndex + 1);
          tilesMoved++;
          targIndex++;
        } else {
          // cannot merge or move
          targIndex++;
        }
      } else {
        // move to empty target
        swapTiles(tiles, curIndex, targIndex);
        tilesMoved++;
      }
    }
  }

  return { tilesMoved, score, tilesMerged };
};

export const populateNewTile = (tiles: Tile[]): boolean => {
  const availableTiles = tiles.filter(x => !x.value);
  if (availableTiles.length) {
    availableTiles[getRandomInt(0, availableTiles.length - 1)].value = 2;
    return true;
  }
  return false;
};

const buildWorkingTiles = (initialTiles: Tile[], direction: Direction): Tile[][] => {
  let workingTiles: Tile[][] = [];
  const isHorizontal = direction === Direction.Left || direction === Direction.Right;
  const selector: keyof Tile = isHorizontal ? 'row' : 'col';
  const gridSize = Math.sqrt(initialTiles.length);
  for (let i = 0; i < gridSize; i++) {
    workingTiles.push(
      sortTiles(
        initialTiles.filter(tile => tile[selector] === i),
        direction,
      ),
    );
  }
  return workingTiles;
};

const getCanRowMove = (tiles: Tile[][]): boolean => {
  for (let i = 0; i < tiles.length; i++) {
    for (let j = 0; j < tiles[i].length - 1; j++) {
      if (tiles[i][j].value! === tiles[i][j + 1].value!) {
        return true;
      }
    }
  }
  return false;
};

export const getPlayState = (tiles: Tile[]): PlayState => {
  const gameWon = tiles.find(x => x.value === 2048);
  if (tiles.find(x => !x.value)) {
    return gameWon ? PlayState.GameWon : PlayState.CanPlay;
  }

  const canMove =
    getCanRowMove(buildWorkingTiles(tiles, Direction.Left)) || getCanRowMove(buildWorkingTiles(tiles, Direction.Down));

  if (!canMove) {
    return PlayState.GameOver;
  }

  return PlayState.CanPlay;
};

export const calculateMove = (gameState: IGameState, direction?: Direction): IGameState => {
  if (direction === undefined) {
    return gameState;
  }
  const workingTiles: Tile[][] = buildWorkingTiles(gameState.tiles, direction);

  const totalUpdate: ITilesUpdate = { tilesMoved: 0, score: 0, tilesMerged: 0 };
  for (let i = 0; i < workingTiles.length; i++) {
    const rowUpdate = moveRow(workingTiles[i], direction);
    totalUpdate.tilesMoved += rowUpdate.tilesMoved;
    totalUpdate.score += rowUpdate.score;
    totalUpdate.tilesMerged += rowUpdate.tilesMerged;
  }

  if (totalUpdate.tilesMoved === 0) {
    return gameState;
  }

  return {
    ...gameState,
    tiles: sortTiles(workingTiles.flatMap(row => row.map(tile => tile))),
    score: gameState.score + totalUpdate.score,
    tilesMoved: gameState.tilesMoved + totalUpdate.tilesMoved,
    tilesMerged: gameState.tilesMerged + totalUpdate.tilesMerged,
    biggestMove: Math.max(gameState.biggestMove, totalUpdate.score),
    biggestTile: gameState.tiles.reduce((agg, cur) => Math.max(cur.value ?? 0, agg), 0),
  };
};
