import React, { useState } from 'react';
import { Button } from '../../shared/components/buttons/Button';
import styled from 'styled-components/macro';
import { CreateTwentyFortyEightStatArgs, TwentyFortyEightStat } from '../../shared/entities/2048';
import { TwentyFortyEightApiProvider, useTwentyFortyEightApi } from '../../shared/api/2048';
import { JsonDisplay } from '../../shared/components/JsonDisplay';
import { useConfig } from '../../core/config';

export const ApiTestRoot = (): JSX.Element => {
  return (
    <TwentyFortyEightApiProvider>
      <ApiTest />
    </TwentyFortyEightApiProvider>
  );
};

const ApiTest = (): JSX.Element => {
  const configService = useConfig();
  const api = useTwentyFortyEightApi();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [state, setState] = useState<object>();
  const [scores, setScores] = useState<TwentyFortyEightStat[]>([]);
  const [newScore, setNewScore] = useState<TwentyFortyEightStat>();
  const [getScoreGridSize, setScoreGridSize] = useState<number>(4);

  const onClick = async () => {
    try {
      const res = await configService.api.get('/ping');
      setState(res.data);
    } catch (e) {
      console.error(e);
      setState({ error: e });
    }
  };

  const onGetScores = async () => {
    const data = await api.getHighScores();
    setScores(data);
  };

  const onCreateScore = async () => {
    const args: CreateTwentyFortyEightStatArgs = {
      gridSize: 4,
      name: 'Casey',
      score: 2048,
      biggestMove: 512,
      biggestTile: 512,
      tilesMerged: 52,
      tilesMoved: 452,
    };
    const data = await api.createStat(args);
    setNewScore(data);
  };

  const setDevMode = () => {
    configService.settings.isDeveloperModeEnabled = !configService.settings.isDeveloperModeEnabled;
    setRefresh(!refresh);
  };

  const handleNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = +e?.target?.value;
    if (newVal) {
      setScoreGridSize(newVal);
    }
  };

  return (
    <ApiTestContainer>
      <h1>ApiTest</h1>
      <Table>
        <tbody>
          <TableRow>
            <TableCell>
              <Button onClick={setDevMode} padding="0.5rem">
                Dev Mode
              </Button>
            </TableCell>
            <TableCell>{configService.settings.isDeveloperModeEnabled ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button onClick={onClick} padding="0.5rem">
                Ping
              </Button>
            </TableCell>
            <TableCell>
              <JsonDisplay name="Ping" src={state} collapsed={false} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button onClick={onGetScores} padding="0.5rem">
                Get Scores
              </Button>
              <NumberInput type="number" value={getScoreGridSize} onChange={handleNumChange} />
            </TableCell>
            <TableCell>
              <JsonDisplay name="2048 Scores" src={scores} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button onClick={onCreateScore} padding="0.5rem">
                Create Score
              </Button>
            </TableCell>
            <TableCell>
              <JsonDisplay name="New Score" src={newScore} />
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </ApiTestContainer>
  );
};

const ApiTestContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Table = styled.table`
  background-color: #939393;
  padding: 5px;

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableRow = styled.tr`
  background-color: #bbbbbb;
  margin: 5px;

  td:first-child {
    text-align: center;
    width: 20%;
  }

  :hover {
    background-color: #ddd;
  }
`;

interface ITableCellProps {
  width?: string;
}

const TableCell = styled.td<ITableCellProps>`
  padding: 0.5rem;

  width: ${({ width }: ITableCellProps) => width ?? 'auto'};
`;

const NumberInput = styled.input`
  margin: 0 0 0 1rem;
  width: 10%;
`;
