import { createContext, ReactNode, useContext } from 'react';
import { TwentyFortyEightApi } from './api';
import { useConfig } from '../../../core/config';

const TwentyFortyEightApiContext = createContext<TwentyFortyEightApi | undefined>(undefined);

export const useTwentyFortyEightApi = (): TwentyFortyEightApi => {
  const service = useContext(TwentyFortyEightApiContext);

  if (!service) {
    throw new Error('useTwentyFortyEightApi must be used within a TwentyFortyEightApiProvider');
  }

  return service;
};

let service: TwentyFortyEightApi;

export const TwentyFortyEightApiProvider = (props: { children: ReactNode }): JSX.Element => {
  const configService = useConfig();

  if (!service) {
    service = new TwentyFortyEightApi(configService);
  }

  return <TwentyFortyEightApiContext.Provider value={service}>{props.children}</TwentyFortyEightApiContext.Provider>;
};
