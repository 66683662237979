import { IGameState, PlayState } from './models/state';
import { KeyInputHandler } from '../../shared/components/input/KeyInputHandler';
import { Button } from '../../shared/components/buttons/Button';
import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { FadeIn } from '../../shared/theme/animations';

interface IGameOverlayProps {
  gameState: IGameState;
  onNewGameClick: () => void;
  onContinueGameClick: () => void;
  hasShownGameWon: boolean;
}

export const GameOverlay = ({
  onContinueGameClick,
  onNewGameClick,
  gameState,
  hasShownGameWon,
}: IGameOverlayProps): JSX.Element => {
  const handleOverlayKeyPress = useCallback(
    (keyCode: string) => {
      const closeBtns = ['Escape', 'NumpadEnter', 'Enter'];
      if (!closeBtns.includes(keyCode)) {
        return;
      }

      if (gameState.playState === PlayState.GameOver) {
        onNewGameClick();
      } else if (gameState.playState === PlayState.GameWon) {
        onContinueGameClick();
      }
    },
    [onContinueGameClick, onNewGameClick, gameState],
  );

  const isGameOver = gameState.playState === PlayState.GameOver;
  const isGameWon = gameState.playState === PlayState.GameWon && !hasShownGameWon;

  return (
    <KeyInputHandler onKeyInput={handleOverlayKeyPress}>
      <GameEndOverlay>
        <ModalTitle>{isGameOver ? 'Game over!' : 'You Won!'}</ModalTitle>
        <StatsSection>
          <StatsTable>
            <thead>
              <tr>
                <th colSpan={2}>Stats</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Score</td>
                <td>{gameState.score}</td>
              </tr>
              <tr>
                <td>Biggest Tile</td>
                <td>{gameState.biggestTile}</td>
              </tr>
              <tr>
                <td>Best Move</td>
                <td>{gameState.biggestMove}</td>
              </tr>
              <tr>
                <td>Tiles Merged</td>
                <td>{gameState.tilesMerged}</td>
              </tr>
              <tr>
                <td>Tiles Moved</td>
                <td>{gameState.tilesMoved}</td>
              </tr>
            </tbody>
          </StatsTable>
        </StatsSection>
        {isGameWon && (
          <Button margin="0 0 0.5rem 0" onClick={() => onContinueGameClick()}>
            Continue Playing
          </Button>
        )}
        <Button onClick={() => onNewGameClick()}>Try Again</Button>
      </GameEndOverlay>
    </KeyInputHandler>
  );
};

const ModalTitle = styled.div`
  font-size: 4rem;
  line-height: 4rem;
`;

const GameEndOverlay = styled.div`
  align-items: center;
  animation: ${FadeIn} 2s;
  background: #eee4daba;
  color: #776e65ff;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const StatsSection = styled.div`
  border-radius: 6px;
  border: 1px solid black;
  background-color: #bbada0;
  color: white;
  display: flex;
  padding: 0.5rem;
  margin: 1rem 0;
  width: 35%;

  @media (max-device-width: 415px) {
    width: 90%;
  }
`;

const StatsTable = styled.table`
  border-collapse: collapse;
  flex: 1;
  font-size: initial;
  text-align: center;

  thead {
    border-bottom: 1px solid #dde2e3;
  }

  td {
    width: 50%;
  }

  th {
    width: 50%;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;
