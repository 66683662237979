export enum Direction {
  Up = 1,
  Right = 2,
  Down = 3,
  Left = 4,
}

export const isOppositeDirection = (direction: Direction, direction2: Direction): boolean => {
  if (direction === Direction.Right) {
    return direction2 === Direction.Left;
  } else if (direction === Direction.Left) {
    return direction2 === Direction.Right;
  } else if (direction === Direction.Up) {
    return direction2 === Direction.Down;
  } else if (direction === Direction.Down) {
    return direction2 === Direction.Up;
  }

  return false;
};

export const getDirectionFromKeyCode = (keyCode: string | undefined): Direction | undefined => {
  switch (keyCode) {
    case 'ArrowUp':
      return Direction.Up;
    case 'ArrowRight':
      return Direction.Right;
    case 'ArrowDown':
      return Direction.Down;
    case 'ArrowLeft':
      return Direction.Left;
    default:
      return undefined;
  }
};

export interface IPosition {
  x: number;
  y: number;
}

export const getDirectionFromCoordinates = (startPos: IPosition, endPos: IPosition): Direction | undefined => {
  const xDiff = startPos.x - endPos.x;
  const yDiff = startPos.y - endPos.y;
  if (Math.abs(xDiff) < 10 && Math.abs(yDiff) < 10) {
    return;
  }
  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    return xDiff > 0 ? Direction.Left : Direction.Right;
  } else {
    return yDiff > 0 ? Direction.Up : Direction.Down;
  }
};
