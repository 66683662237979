import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Direction, getDirectionFromCoordinates, getDirectionFromKeyCode, IPosition } from '../../models/direction';
import styled from 'styled-components/macro';

interface IInputDirectionHandlerProps {
  children?: ReactNode;
  onDirectionInput: (direction: Direction) => void;
  disabled?: boolean;
}

export const InputDirectionHandler = ({
  children,
  onDirectionInput,
  disabled,
}: IInputDirectionHandlerProps): JSX.Element => {
  const [startPos, setStartPos] = useState<IPosition>({ x: 0, y: 0 });
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (divRef?.current && !disabled) {
      divRef?.current?.focus();
    }
  }, [divRef, children, disabled]);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();

      setStartPos({
        x: e.changedTouches[0]?.clientX,
        y: e.changedTouches[0]?.clientY,
      });
    },
    [setStartPos],
  );

  const handleTouchEnd = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const endPos = {
        x: e.changedTouches[0]?.clientX,
        y: e.changedTouches[0]?.clientY,
      };
      const direction = getDirectionFromCoordinates(startPos, endPos);
      if (direction && !disabled) {
        onDirectionInput(direction);
      }
    },
    [onDirectionInput, startPos, disabled],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      const direction = getDirectionFromKeyCode(e.code);
      if (direction && !disabled) {
        onDirectionInput(direction);
      }
    },
    [onDirectionInput, disabled],
  );

  return (
    <InputDiv
      ref={divRef}
      tabIndex={1}
      onKeyDown={handleKeyDown}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </InputDiv>
  );
};

const InputDiv = styled.div`
  align-content: center;
  display: flex;
  justify-content: center;
  touch-action: none;

  &:focus-visible {
    outline: none;
  }
`;
