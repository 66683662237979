import { keyframes } from 'styled-components/macro';

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MoveUp = keyframes`
  0% {
    top: 25px;
    opacity: 1;
  }
  100% {
    top: -50px;
    opacity: 0;
  }
`;

export const LowerBrightness = keyframes`
  0% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(75%);
  }
`;
