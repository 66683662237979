import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridNode, IGameState, PlayState, SnakeNode } from '../models/state';
import { Direction, isOppositeDirection } from '../../../shared/models/direction';
import { updateSnakeState } from '../services/snakeService';
import styled from 'styled-components/macro';
import { InputDirectionHandler } from '../../../shared/components/input/InputDirectionHandler';
import { Button } from '../../../shared/components/buttons/Button';
import { Grid } from './Grid';
import { FadeIn } from '../../../shared/theme/animations';
import { getRandomInt } from '../../../shared/services/utils';
import { KeyInputHandler } from '../../../shared/components/input/KeyInputHandler';

const getStartingSnake = (len: number): SnakeNode[] => {
  const snake: SnakeNode[] = [];
  for (let i = len; i >= 0; i--) {
    snake.push({ row: 0, col: i, id: len - i });
  }
  return snake;
};

const getStartingState = (gridSize: number): IGameState => {
  let items: GridNode[] = [{ row: getRandomInt(1, gridSize), col: getRandomInt(0, gridSize), value: 'X' }];

  return {
    items,
    direction: Direction.Right,
    snake: getStartingSnake(2),
    playState: PlayState.CanPlay,
    gridSize,
  };
};

export const Snake = (): JSX.Element => {
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [gameSpeed] = useState<number>(100);
  const [state, setState] = useState<IGameState>(getStartingState(20));

  useEffect(() => {
    let id: NodeJS.Timeout | undefined;
    if (!isPaused && state.playState) {
      id = setTimeout(() => {
        const newState = updateSnakeState(state);
        setState(newState);
      }, gameSpeed);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [state, setState, gameSpeed, isPaused]);

  const handleDirectionInput = useCallback(
    (direction: Direction) => {
      const canChangeDir = state.playState === PlayState.CanPlay;
      if (canChangeDir && direction && !isOppositeDirection(direction, state.direction)) {
        setState(prev => ({ ...prev, direction, playState: PlayState.CantMove }));
      }
    },
    [state, setState],
  );

  const handleResetClick = useCallback(() => {
    setIsPaused(false);
    setState(getStartingState(state.gridSize));
  }, [state.gridSize]);

  const handlePauseClick = () => {
    setIsPaused(prev => !prev);
  };

  const handleOverlayKeyPress = useCallback(
    (keyCode: string) => {
      const closeBtns = ['Escape', 'NumpadEnter', 'Enter'];
      if (state.playState === PlayState.GameOver && closeBtns.includes(keyCode)) {
        handleResetClick();
      }
    },
    [handleResetClick, state.playState],
  );

  const grid = useMemo(() => <Grid gridSize={state.gridSize} items={state.items} />, [state.gridSize, state.items]);

  return (
    <Container>
      <Panel>
        <PanelRow>
          <Button onClick={handlePauseClick}>{isPaused ? 'Play' : 'Pause'}</Button>
          <PanelItem>
            <span>Length</span>
            <span>{state.snake.length - 1}</span>
          </PanelItem>
        </PanelRow>
      </Panel>
      {state.playState === PlayState.GameOver && (
        <KeyInputHandler onKeyInput={handleOverlayKeyPress}>
          <GameEndOverlay>
            <div>Game over!</div>
            <Button onClick={handleResetClick}>Try Again</Button>
          </GameEndOverlay>
        </KeyInputHandler>
      )}
      <InputDirectionHandler onDirectionInput={handleDirectionInput}>
        <SnakeContainer gridSize={state.gridSize}>
          {grid}
          {state.snake.map(snakeNode => {
            return (
              <SnakeCell
                key={`snake-${snakeNode.id}`}
                col={snakeNode.col}
                row={snakeNode.row}
                isHead={snakeNode.id === 0}
                direction={state.direction}
              />
            );
          })}
        </SnakeContainer>
      </InputDirectionHandler>
    </Container>
  );
};

const Container = styled.div`
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: min-content;
`;

interface ISnakeContainerProps {
  gridSize: number;
}

const SnakeContainer = styled.div<ISnakeContainerProps>`
  --grid-size: ${({ gridSize }: ISnakeContainerProps) => gridSize};
  --game-size: 80vmin;
  --tile-size: calc(var(--game-size) / var(--grid-size));
  --move-resolution: var(--tile-size);
  background-color: #bbada0;
  border-radius: 6px;
  height: var(--game-size);
  margin-top: 1rem;
  min-height: var(--game-size);
  position: relative;
  touch-action: cross-slide-x cross-slide-y;
  width: var(--game-size);

  @media (max-device-width: 415px) {
    --game-size: 90vmin;
  }
`;

interface ISnakeCellProps {
  isHead: boolean;
  col: number;
  row: number;
  direction: Direction;
}

const SnakeCell = styled.div.attrs((props: ISnakeCellProps) => ({
  style: {
    backgroundColor: props.isHead ? 'yellow' : 'red',
    transform: `translate(calc(${props.col} * var(--move-resolution)), calc(${props.row} * var(--move-resolution)))`,
  },
}))`
  align-items: flex-start;
  background-color: ${({ isHead }: ISnakeCellProps): string => (isHead ? 'yellow' : 'red')};
  border-radius: 40%;
  display: flex;
  height: var(--tile-size);
  justify-content: flex-end;
  position: absolute;
  transition: transform 125ms ease-out;
  width: var(--tile-size);
`;

const GameEndOverlay = styled.div`
  align-items: center;
  animation: ${FadeIn} 2s;
  background: #eee4daba;
  color: #776e65ff;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const Panel = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const PanelRow = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0 0 1rem 0;
  justify-content: space-between;
  width: 100%;

  &:last-child {
    margin: 0;
  }
`;

const PanelItem = styled.div`
  align-items: center;
  background-color: #bbada0;
  border: 1px solid black;
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  position: relative;
  padding: 0.5em;
`;
