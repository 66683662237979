import React from 'react';
import { Tile } from '../../shared/models/tile';
import styled from 'styled-components/macro';

interface ITilesProps {
  tiles: Tile[];
}

export const Tiles = ({ tiles }: ITilesProps): JSX.Element => {
  return (
    <TilesContainer>
      {tiles.map(tile => (
        <TileCell col={tile.col} row={tile.row} key={`tile${tile.id}`} value={tile.value}>
          <div>
            <h1>{tile.value}</h1>
          </div>
        </TileCell>
      ))}
    </TilesContainer>
  );
};

const TilesContainer = styled.div`
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
`;

interface ITileCellProps {
  value: number | undefined;
  col: number;
  row: number;
}

const TileCell = styled.div.attrs((props: ITileCellProps) => ({
  style: {
    transform: `translate(calc(${props.col} * var(--tile-size)), calc(${props.row} * var(--tile-size)))`,
    opacity: props.value ? 1 : 0,
  },
}))`
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  height: var(--tile-size);
  position: absolute;
  width: var(--tile-size);
  transition: transform 100ms ease-in-out, opacity 100ms ease-in;

  > div {
    align-items: center;
    border-radius: 3px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    background-color: ${({ value }: ITileCellProps) => (value ? TileColors[value] : 'transparent')};
`;

const TileColors: { [name: number]: string } = {
  2: '#EEE4DA',
  4: '#EDE0C5',
  8: '#F7B474',
  16: '#FD9C5E',
  32: '#FF865B',
  64: '#FF6B37',
  128: '#F0CB6A',
  256: '#F0C858',
  512: '#E2B845',
  1024: '#E3B536',
  2048: '#E4B224',
  4096: '#E7A20D',
  8192: '#E78B00',
  16384: '#E78200',
  32768: '#e77800',
};
