import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

interface IInputDirectionHandlerProps {
  children?: ReactNode;
  onKeyInput: (keyCode: string) => void;
}

export const KeyInputHandler = ({ children, onKeyInput }: IInputDirectionHandlerProps): JSX.Element => {
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (divRef?.current) {
      divRef?.current?.focus();
    }
  }, [divRef, children]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      onKeyInput(e.code);
    },
    [onKeyInput],
  );

  return (
    <InputDiv ref={divRef} tabIndex={1} onKeyDown={handleKeyDown}>
      {children}
    </InputDiv>
  );
};

const InputDiv = styled.div`
  align-content: center;
  display: flex;
  justify-content: center;
  touch-action: none;

  &:focus-visible {
    outline: none;
  }
`;
