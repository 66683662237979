import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { LowerBrightness } from '../../theme/animations';

interface IButtonProps {
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showBorder?: boolean;
  margin?: string;
  width?: string;
  padding?: string;
}

export const Button = ({
  onClick,
  children,
  showBorder = true,
  margin = '0',
  width,
  padding,
}: IButtonProps): JSX.Element => {
  return (
    <StyledButton showBorder={showBorder} margin={margin} onClick={onClick} width={width} padding={padding}>
      {children}
    </StyledButton>
  );
};

interface IStyledButtonProps {
  showBorder: boolean;
  margin: string;
  width?: string;
  padding?: string;
}

const StyledButton = styled.button<IStyledButtonProps>`
  cursor: pointer;
  border: ${({ showBorder }: IStyledButtonProps) => (showBorder ? '1px solid black' : 'none')};
  border-radius: 6px;
  color: #ffffff;
  background-color: #bbada0;
  font-size: 16px;
  font-weight: bold;
  padding: ${({ padding }: IStyledButtonProps) => padding ?? `1rem`};
  margin: ${({ margin }: IStyledButtonProps) => margin};
  width: ${({ width }: IStyledButtonProps) => width};

  @media (min-device-width: 415px) {
    &:hover {
      animation: ${LowerBrightness} 150ms ease-out;
      animation-fill-mode: both;
    }
  }
`;
