import { TwentyFortyEightApiProvider } from '../../shared/api/2048';
import { TwentyFortyEight } from './TwentyFortyEight';
import React from 'react';

export const TwentyFortyEightRoot = (): JSX.Element => {
  return (
    <TwentyFortyEightApiProvider>
      <TwentyFortyEight />
    </TwentyFortyEightApiProvider>
  );
};
