import React from 'react';
import styled from 'styled-components/macro';
import { GridNode } from '../models/state';
import { FadeIn } from '../../../shared/theme/animations';

interface IGridProps {
  gridSize: number;
  items: GridNode[];
}

export const Grid = ({ gridSize, items }: IGridProps): JSX.Element => {
  return (
    <GridContainer>
      {Array(gridSize)
        .fill(0)
        .flatMap((val, rowIndex) => {
          return Array(gridSize)
            .fill(0)
            .map((colVal, colIndex) => {
              const hasItem = items.some(item => item.row === rowIndex && item.col === colIndex);
              return (
                <GridCell key={`grid(${rowIndex},${colIndex})`}>
                  {hasItem && (
                    <Item>
                      <ItemIcon alt="item" src="https://avatars.githubusercontent.com/u/73187286?v=4" />
                    </Item>
                  )}
                </GridCell>
              );
            });
        })}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-template-rows: repeat(var(--grid-size), 1fr);
  height: 100%;
  position: absolute;
  width: 100%;
`;

const GridCell = styled.div`
  background-color: #eee4da59;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin: 1px;
  position: relative;
`;

const Item = styled.div`
  align-items: center;
  animation: ${FadeIn} 500ms;
  background-color: rgb(100, 96, 92);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: auto;
  max-width: initial;
  position: absolute;
  width: 100%;

  > svg {
    height: calc(var(--tile-size) - 0.5rem);
  }
`;

const ItemIcon = styled.img`
  border-radius: 50%;
  overflow: auto;
`;
