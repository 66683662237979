import React, { useState } from 'react';
import { IGameState, Player, PlayState } from '../models/state';
import styled from 'styled-components/macro';
import { Button } from '../../../shared/components/buttons/Button';
import { FadeIn } from '../../../shared/theme/animations';

const getStartingState = (): IGameState => {
  let tiles: (string | null)[][] = [];
  for (let i = 0; i < 3; i++) {
    tiles.push([null, null, null]);
  }
  return {
    playState: PlayState.CanPlay,
    tiles,
    currentPlayer: 'X',
  };
};

const isWinner = (tiles: (string | null)[][]): boolean => {
  const diag1 = [];
  const diag2 = [];
  for (let i = 0; i < tiles.length; i++) {
    const row = tiles[i];
    if (row[0] && row.every((x) => x === row[0])) {
      return true;
    }

    const col = tiles.map((tileRow) => tileRow[i]);
    if (col[0] && col.every((x) => x === col[0])) {
      return true;
    }

    diag1.push(row[i]);
    diag2.push(row[row.length - 1 - i]);
  }

  const mid = tiles[1][1];
  return !!(mid && (diag1.every((x) => x === mid) || diag2.every((x) => x === mid)));
};

const isGameOver = (tiles: (string | null)[][]): boolean => {
  return tiles.every((row) => row.every((x) => !!x));
};

export const TicTacToe = (): JSX.Element => {
  const [state, setState] = useState<IGameState>(getStartingState());

  const setTile = (rowIndex: number, tileIndex: number) => {
    if (state.tiles[rowIndex][tileIndex]) {
      return;
    }

    const tiles = [...state.tiles];
    tiles[rowIndex][tileIndex] = state.currentPlayer;

    let playState = PlayState.CanPlay;
    let currentPlayer: Player = state.currentPlayer;

    if (isWinner(tiles)) {
      playState = PlayState.GameWon;
    } else if (isGameOver(tiles)) {
      playState = PlayState.GameOver;
    } else {
      currentPlayer = state.currentPlayer === 'X' ? 'O' : 'X';
    }

    setState({ playState, currentPlayer, tiles });
  };

  const resetGame = () => {
    setState(getStartingState());
  };

  return (
    <div>
      <InfoPanel>
        <Button onClick={resetGame}>Reset</Button>
      </InfoPanel>
      <TicTacToeContainer>
        <Grid>
          {state.tiles.flatMap((row, rowIndex) =>
            row.map((tile, tileIndex) => (
              <GridCell key={`${rowIndex}${tileIndex}`} isFilled={!!tile} onClick={() => setTile(rowIndex, tileIndex)}>
                {tile}
              </GridCell>
            )),
          )}
        </Grid>
        {state.playState !== PlayState.CanPlay && (
          <GameEndOverlay>
            <div>Game over!</div>
            {state.playState === PlayState.GameWon && <PlayerWonText>Player {state.currentPlayer} Won</PlayerWonText>}
            <Button margin="1rem" onClick={resetGame}>
              Play Again
            </Button>
          </GameEndOverlay>
        )}
      </TicTacToeContainer>
    </div>
  );
};

const TicTacToeContainer = styled.div`
  --grid-size: 3;
  background-color: #bbada0;
  border-radius: 6px;
  height: 80vmin;
  min-height: var(--game-size);
  position: relative;
  width: 80vmin;
  touch-action: cross-slide-x cross-slide-y;
`;

const Grid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: repeat(var(--grid-size), 1fr);
  grid-template-columns: repeat(var(--grid-size), 1fr);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

interface IGridCellProps {
  isFilled: boolean;
}

const GridCell = styled.div<IGridCellProps>`
  align-items: center;
  background: rgba(238, 228, 218, 0.35);
  border-radius: 3px;
  box-sizing: border-box;
  cursor: ${({ isFilled }: IGridCellProps): string => (isFilled ? 'default' : 'pointer')};
  display: flex;
  font-size: 3rem;
  font-weight: bold;
  justify-content: center;
  margin: 5px;
`;

const PlayerWonText = styled.div`
  font-size: 0.75em;
`;

const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0.75rem 0;
`;

const GameEndOverlay = styled.div`
  animation: ${FadeIn} 1s;
  align-items: center;
  background: #eee4daba;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  font-weight: bold;
  height: 100%;
  position: absolute;
  justify-content: center;
  width: 100%;
  z-index: 3;
`;
