import { Direction } from '../../../shared/models/direction';

export interface SnakeNode {
  row: number;
  col: number;
  id: number;
}

export interface GridNode {
  row: number;
  col: number;
  value: string;
}

export enum PlayState {
  GameOver,
  CanPlay,
  CantMove,
}

export interface IGameState {
  items: GridNode[];
  snake: SnakeNode[];
  direction: Direction;
  playState: PlayState;
  gridSize: number;
}
