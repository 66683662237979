export enum PlayState {
  CanPlay,
  GameOver,
  GameWon,
}

export type Player = 'X' | 'O';

export interface IGameState {
  tiles: (string | null)[][];
  playState: PlayState;
  currentPlayer: Player;
}
