import { CreateTwentyFortyEightStatArgs, TwentyFortyEightStat } from '../../entities/2048';
import { ConfigService } from '../../../core/config';
import { AxiosInstance } from 'axios';

export class TwentyFortyEightApi {
  private readonly basePath: string = '/2048';
  private readonly api: AxiosInstance;

  constructor(configService: ConfigService) {
    this.api = configService.api;
  }

  public getHighScores = async (): Promise<TwentyFortyEightStat[]> => {
    const res = await this.api.get(`${this.basePath}/getHighScores`);
    return res.data;
  };

  public createStat = async (args: CreateTwentyFortyEightStatArgs): Promise<TwentyFortyEightStat> => {
    const res = await this.api.post(`${this.basePath}/createStat`, args);
    return res.data;
  };
}
