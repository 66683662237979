import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../../shared/components/buttons/Button';
import { MoveUp } from '../../shared/theme/animations';
import { Prompt } from '../../shared/components/Prompt';
import { IGameState } from './models/state';
import { HighScoresModal } from './HighScoresModal';

interface IInformationPanelProps {
  onNewGame: () => void;
  highScore: number;
  scoreDiff: number;
  gameState: IGameState;
}

export const InfoPanel = ({ highScore, onNewGame, scoreDiff, gameState }: IInformationPanelProps): JSX.Element => {
  const [isScoreDiffAnim, setIsScoreDiffAnim] = useState<boolean>(false);
  const [promptState, setPromptState] = useState<{ isOpen: boolean; value?: number }>({ isOpen: false });
  const [isHighScoreModalOpen, setIsHighScoreModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (scoreDiff > 0) {
      setIsScoreDiffAnim(true);
      setTimeout(() => {
        setIsScoreDiffAnim(prevState => !prevState);
      }, 500);
    }
  }, [scoreDiff, setIsScoreDiffAnim]);

  const handleNewGameConfirm = useCallback(() => {
    setPromptState({ isOpen: false });
    onNewGame();
  }, [setPromptState, onNewGame]);

  const handleNewGameCancel = useCallback(() => {
    setPromptState({ isOpen: false });
  }, [setPromptState]);

  const handleNewGameClick = useCallback(() => {
    setPromptState({ isOpen: true });
  }, [setPromptState]);

  const onOpenHighScores = () => {
    setIsHighScoreModalOpen(true);
  };

  const onCloseHighScores = () => {
    setIsHighScoreModalOpen(false);
  };

  return (
    <Panel>
      <HighScoresModal isOpen={isHighScoreModalOpen} onClose={onCloseHighScores} />
      <Prompt
        isOpen={promptState.isOpen}
        message="Are you sure you want to reset the current game? Any progress will be lost."
        onClose={handleNewGameCancel}
        onConfirm={handleNewGameConfirm}
        value={promptState.value}
      />
      <PanelRow>
        <PanelItem>
          {isScoreDiffAnim && <ScoreDiff>+{scoreDiff}</ScoreDiff>}
          <span>Score</span>
          <span>{gameState.score}</span>
        </PanelItem>

        <PanelItem>
          <span>Best</span>
          <span>{highScore}</span>
        </PanelItem>
      </PanelRow>
      <PanelRow2>
        <Button onClick={handleNewGameClick}>New Game</Button>
        <Button onClick={onOpenHighScores}>HighScores</Button>
      </PanelRow2>
    </Panel>
  );
};

const Panel = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0;
`;

const PanelRow = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0 0 1rem 0;
  justify-content: flex-end;
  width: 100%;

  &:last-child {
    margin: 0;
  }
`;

const PanelRow2 = styled(PanelRow)`
  justify-content: flex-end;
`;

const PanelItem = styled.div`
  align-items: center;
  background-color: #bbada0;
  border: 1px solid black;
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  position: relative;
  padding: 0.5em;
  width: 20%;
`;

const ScoreDiff = styled.div`
  animation: ${MoveUp} 500ms ease-in;
  animation-fill-mode: both;
  color: #776e65e5;
  font-size: 1.75rem;
  font-weight: bold;
  position: absolute;
`;
