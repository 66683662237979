import React, { useMemo } from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { useConfig } from '../../core/config';

interface IDevModeDisplayJSONProps extends ReactJsonViewProps {
  fontSize?: number;
  src: object | any;
}

export const JsonDisplay = (props: IDevModeDisplayJSONProps): JSX.Element => {
  const config = useConfig();

  const canDisplayJson = useMemo(() => {
    let hasValue = props.src instanceof Array ? !!props.src.length : props.src;
    return config.settings.isDeveloperModeEnabled && hasValue;
  }, [config.settings.isDeveloperModeEnabled, props.src]);

  if (!canDisplayJson) {
    return <></>;
  }

  return (
    <ReactJson
      collapsed={true}
      quotesOnKeys={false}
      sortKeys={false}
      style={{ fontSize: props.fontSize || 14 }}
      {...props}
    />
  );
};
