import { Tile } from '../../../shared/models/tile';

export enum PlayState {
  CanPlay,
  GameOver,
  GameWon,
}

export interface ITilesUpdate {
  tilesMoved: number;
  score: number;
  tilesMerged: number;
}

export interface IGameState {
  tiles: Tile[];
  score: number;
  tilesMoved: number;
  playState: PlayState;
  gridSize: number;
  biggestMove: number;
  tilesSpawned: number;
  tilesMerged: number;
  biggestTile: number;
}

export type HighScores = { [name: number]: number };
