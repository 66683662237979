import { SnakeScreenShot, TicTacToeScreenShot, TwentyFortyEightScreenShot } from '../assets/images';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouteDefinitions } from '../routes';
import styled from 'styled-components/macro';
import { LowerBrightness } from '../shared/theme/animations';

export const Home = (): JSX.Element => {
  return (
    <HomeContainer>
      <Title>Scuffed Games</Title>
      <GamesList>
        <NavItem to={RouteDefinitions.TwentyFortyEight}>
          <h1>2048</h1>
          <img alt="2048" src={TwentyFortyEightScreenShot} />
        </NavItem>
        <NavItem to={RouteDefinitions.TicTacToe}>
          <h1>Tic Tac Toe</h1>
          <img alt="tic tac toe" src={TicTacToeScreenShot} />
        </NavItem>
        <NavItem to={RouteDefinitions.Snake}>
          <h1>Snake</h1>
          <img alt="snake" src={SnakeScreenShot} />
        </NavItem>
      </GamesList>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const Title = styled.div`
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const GamesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;

  @media (max-device-width: 415px) {
    flex-direction: column;
  }
`;

const NavItem = styled(NavLink)`
  color: white;
  align-items: center;
  background-color: dimgray;
  border-radius: 1rem;
  padding: 0.5rem;
  text-decoration: none;
  flex: 1;

  @media (min-device-width: 415px) {
    &:hover {
      animation: ${LowerBrightness} 150ms ease-out;
      animation-fill-mode: both;
    }
  }

  > img {
    border-radius: 1rem;
    height: auto;
    object-fit: contain;
    width: 100%;
  }
`;
