import { useContext, createContext, ReactNode } from 'react';
import { ConfigService } from './config.service';

const ConfigServiceContext = createContext<ConfigService | undefined>(undefined);

export const useConfig = (): ConfigService => {
  const service = useContext(ConfigServiceContext);

  if (!service) {
    throw new Error('useConfigService must be used within a ConfigServiceProvider');
  }

  return service;
};

let service: ConfigService;

export const ConfigProvider = (props: { children: ReactNode }): JSX.Element => {
  if (!service) {
    service = new ConfigService();
  }

  return <ConfigServiceContext.Provider value={service}>{props.children}</ConfigServiceContext.Provider>;
};
