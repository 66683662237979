import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTwentyFortyEightApi } from '../../shared/api/2048';
import { TwentyFortyEightStat } from '../../shared/entities/2048';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons/faChevronCircleUp';

interface IHighScoresModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const HighScoresModal = ({ isOpen, onClose }: IHighScoresModalProps): JSX.Element => {
  const [highScores, setHighScores] = useState<TwentyFortyEightStat[]>([]);
  const [expandedScoreId, setExpandedScoreId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const api = useTwentyFortyEightApi();

  useEffect(() => {
    if (isOpen) {
      const getHighScores = async () => {
        const scores = await api.getHighScores();
        setHighScores(scores);
        setIsLoading(false);
      };
      setIsLoading(true);
      getHighScores();
    }
  }, [api, isOpen]);

  const onAccordionClick = (scoreId: string) => {
    const newScoreId = expandedScoreId === scoreId ? undefined : scoreId;
    setExpandedScoreId(newScoreId);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <Title>High Scores</Title>
      <Content dividers>
        {isLoading && (
          <Box textAlign="center" p={2}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <Table stickyHeader>
            <TableHead>
              <TableHeadRow>
                <TableCell>Rank</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Score</TableCell>
                <TableCell />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {highScores.map((score, index) => {
                const isOpen = score.id === expandedScoreId;
                return (
                  <React.Fragment key={score.id}>
                    <TableRow onClick={() => onAccordionClick(score.id)}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{score.name || 'Unknown'}</TableCell>
                      <TableCell>{score.score}</TableCell>
                      <TableCell>
                        <IconButton size="small">
                          <FontAwesomeIcon icon={isOpen ? faChevronCircleUp : faChevronCircleDown} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <CollapseTableCell colSpan={4}>
                        <Collapse in={isOpen}>
                          <Paper sx={{ margin: 1 }}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Biggest Tile</TableCell>
                                  <TableCell>Best Move</TableCell>
                                  <TableCell>Tiles Merged</TableCell>
                                  <TableCell>Tiles Moved</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>{score.biggestTile}</TableCell>
                                  <TableCell>{score.biggestMove}</TableCell>
                                  <TableCell>{score.tilesMerged}</TableCell>
                                  <TableCell>{score.tilesMoved}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Collapse>
                      </CollapseTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Content>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Content = styled(DialogContent)`
  //noinspection CssInvalidPropertyValue
  overflow: overlay;
  padding-top: 0;
  @media (max-device-width: 415px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CollapseTableCell = styled(MuiTableCell)`
  padding: 0;
`;

const TableCell = styled(MuiTableCell)`
  text-align: center;
`;

const TableHeadRow = styled(TableRow)`
  > th {
    font-weight: bold;
  }
`;

const Title = styled(DialogTitle)`
  font-weight: bold;
  text-align: center;
`;
