import React from 'react';
import styled from 'styled-components/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { LowerBrightness } from '../../theme/animations';

interface IButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: IconDefinition;
  color?: string;
  size?: SizeProp;
}

export const IconButton = ({ onClick, icon, color, size }: IButtonProps): JSX.Element => {
  return (
    <StyledButton onClick={onClick}>
      <FontAwesomeIcon size={size} icon={icon} color={color} />
    </StyledButton>
  );
};

interface IStyledButtonProps {}

const StyledButton = styled.button<IStyledButtonProps>`
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-weight: bold;
  padding: 0;

  @media (min-device-width: 415px) {
    &:hover {
      animation: ${LowerBrightness} 150ms ease-out;
      animation-fill-mode: both;
    }
  }
`;
