import { GridNode, IGameState, PlayState, SnakeNode } from '../models/state';
import { Direction } from '../../../shared/models/direction';
import { getRandomInt } from '../../../shared/services/utils';

const moveItem = (items: GridNode[], snake: SnakeNode[], itemIndex: number, gridSize: number) => {
  let newItem = items[itemIndex];
  let hasCollision = true;
  do {
    newItem.row = getRandomInt(0, gridSize);
    newItem.col = getRandomInt(0, gridSize);
    hasCollision =
      items.some(item => item.col === newItem.col && item.row === newItem.row) ||
      snake.some(snakeNode => snakeNode.col === newItem.col && snakeNode.row === newItem.row);
  } while (!hasCollision);
};

export const updateSnakeState = (state: IGameState): IGameState => {
  const snake = [...state.snake];
  let items = [...state.items];

  let colDiff = state.direction === Direction.Right ? 1 : state.direction === Direction.Left ? -1 : 0;
  let rowDiff = state.direction === Direction.Down ? 1 : state.direction === Direction.Up ? -1 : 0;

  const head = snake[0];
  let newTail: SnakeNode | undefined;

  const hitItemIndex = items.findIndex(item => item.row === head.row && item.col === head.col);
  if (hitItemIndex !== -1) {
    newTail = { ...snake[snake.length - 1] };
    newTail.id = snake.length;
    moveItem(items, snake, hitItemIndex, state.gridSize);
  }

  const tileLen = state.gridSize - 1;
  const nextHead: SnakeNode = { row: head.row + rowDiff, col: head.col + colDiff, id: 0 };

  let invalidMove =
    nextHead.col < 0 ||
    nextHead.col > tileLen ||
    nextHead.row < 0 ||
    nextHead.row > tileLen ||
    snake.some((node, index) => index !== 0 && node.col === nextHead.col && node.row === nextHead.row);

  if (!invalidMove) {
    for (let i = snake.length - 1; i >= 1; i--) {
      const prev = snake[i - 1];
      snake[i].row = prev.row;
      snake[i].col = prev.col;
    }

    head.col += colDiff;
    head.row += rowDiff;
  }

  if (newTail) {
    snake.push(newTail);
  }

  return {
    snake,
    direction: state.direction,
    items: items,
    playState: invalidMove ? PlayState.GameOver : PlayState.CanPlay,
    gridSize: state.gridSize,
  };
};
